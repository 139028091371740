/**
 * スクロール管理
 */

export const state = () => ({
  isScrollingUp: false,
  isScrollingDown: false,
  isTopPosition: false,
  isBottomPosition: false,
})

export const mutations = {
  isScrollingUp (state, flag) {
    state.isScrollingUp = flag
  },
  isScrollingDown (state, flag) {
    state.isScrollingDown = flag
  },
  isTopPosition (state, flag) {
    state.isTopPosition = flag
  },
  isBottomPosition (state, flag) {
    state.isBottomPosition = flag
  },
}

export const actions = {
  isScrollingUp (context, flag) {
    context.commit('isScrollingUp', flag)
  },
  isScrollingDown (context, flag) {
    context.commit('isScrollingDown', flag)
  },
  isTopPosition (context, flag) {
    context.commit('isTopPosition', flag)
  },
  isBottomPosition (context, flag) {
    context.commit('isBottomPosition', flag)
  },
}
