/**
 * サイト会員情報
 */
import default_image from '~/assets/img/profile.jpg'; // デフォルト画像

export const state = () => ({
  id: '', // 会員ID
  uuid: '', // 会員UUID
  tel: '', // 電話番号
  nickname: '', // ニックネーム
  selfIntroduction: '', // 自己紹介
  imageFilename: '', // プロフィール画像ファイル名
  profileImageUrl: '', // プロフィール画像URL
  profileImageCheckStatus: '',
  point: '', // ポイント
  mapAreaId: '',
  area_ids: '',
  sub_area_ids: '',
  home_area_id: '', // 自宅エリア
  home_sub_area_id: '', // 自宅サブエリア
  home_address: '', // 自宅住所
  home_building: '', // 自宅建物名
  home_room_number: '', // 自宅部屋番号
  home_hope_flag: '', // 自宅希望フラグ
  prefecture_id: 13, // 自宅都道府県
  searchType: 1, //検索タイプ 1: 今すぐ、2: レンジ、3: 日時指定なし
  search_prefecture_id: 13,
  search_cast_age_min: '',
  search_cast_age_max: '',
  search_cast_height_min: '',
  search_cast_height_max: '',
  search_cast_cup_min: '',
  search_cast_cup_max: '',
  search_cast_s_degree: '',
  search_cast_m_degree: '',
  search_cast_budget_num_min: '',
  search_cast_budget_num_max: '',
  search_cast_budget_amount_min: '',
  search_cast_budget_amount_max: '',
  budgetValue: '',
  castAgeValue: '',
  castHeightValue: '',
  castCupValue: '',
  castSmValue: '',
  search_cast_genre_ids: '',
  search_cast_shop_ids: [],
  search_cast_shop_obj: [],
  selectedCastIds: [], // 選択されたキャストId [44, 45]
  selectedCastObj: [],
  selectedHotelId: '',
  selectedHotelName: '',
  selectLanguage: 'en',
  selectedHotel: {},
  reservationNgFlag: '', // 予約NGフラグ
  last_use_place_search_type: '', // 前回の利用場所検索タイプ（1：自宅、2：ホテル）
  recommend_site_member_important_cast_features: [
    {category: 1, point: 1},
    {category: 2, point: 1},
    {category: 3, point: 1},
    {category: 4, point: 1},
    {category: 5, point: 1},
  ],
  favaliteTagIds: [],
  ngSmokeValue: '',
  ngTattooValue: '',
  ngBodyPiercingFlag: 0,
  searchUnderHairType: '',
  newCastFlag: '',
  searchDrinkType: '',
  talent_ng_site_members: [],
  selectPlace: 'selected-hotel', // ホテル(selected-hotel) or 自宅(selected-home)
  sortType: 1, // ソートタイプ 1: マッチ度順, 2: 入店順
  showUnreservable: false, // 表示タイプ true: 全て表示, false: 予約可のみ表示,
  profilePlayOptionCategoryIds: [],
  showAllCasts: false, // 表示タイプ true: 全て表示, false: 予約可のみ表示
  otherAreaPanel: false, // その他のエリアパネル(false: 閉じる, true: 開く)
  // likedPhotoDiaryLimit: false, // 写メ日記「いいね！」制御
  // maxLikedPhotoDiary: 0, // 写メ日記「いいね！」上限回数
  // helpFullReviewLimit: false, // クチコミ「参考になった」制御
  // maxHelpFullReview: 0, // クチコミ「参考になった」上限回数
  // followSiteMemberLimit: false, // 会員「フォロー」制御
  // maxFollowSiteMember: 0, // 会員「フォロー」上限回数
  pointBalance: 0, // ポイント残高
  gradeId: 0, // グレード
  star: 0, // スター
  frequentlyPlayedArea: '', // よく遊ぶエリア（フリーテキスト）
  frequentlyPlayedGenreIds: [], // よく遊ぶジャンル（複数選択可能）
  frequentlyPlayedShopId: '', // よく遊ぶお店（一店舗のみ選択）
  starPublicFlag: true, // スターの公開フラグ
  gradePublicFlag: true, // グレードの公開フラグ
  favoriteCastCount: 0,
  reviewCount: 0,
  followCount: 0,
  followerCount: 0,
  followCastIds: [],
});

export const mutations = {
  // 取得したデータを保持
  saveSiteMemberData(state, data) {
    state.id = data.id;
    state.uuid = data.uuid;
    state.tel = data.tel;
    state.nickname = data.nickname;
    state.selfIntroduction = data.selfIntroduction;
    state.point = data.point;
    state.area_ids = data.area_ids;
    if(data.noUseFlag == 0){
      state.noUseFlag = false;
    }else{
      state.noUseFlag = true;
    }
    state.imageFilename = data.imageFilename;
    // プロフィール画像URL
    if(data.imageFilename == null){
      state.profileImageUrl = default_image; // デフォルト画像
    }else{
      state.profileImageUrl = `${process.env.AWS_S3_URL}site_members/${data.id}/mypage/photo/${data.imageFilename}`;
    }
    state.profileImageCheckStatus = data.profileImageCheckStatus;
    state.recommend_site_member_important_cast_features = data.recommend_site_member_important_cast_features;
    state.favaliteTagIds = data.favaliteTagIds;
    state.ngSmokeValue = data.ngSmokeValue;
    state.ngTattooValue = data.ngTattooValue;
    state.ngBodyPiercingFlag = data.ngBodyPiercingFlag;
    state.searchUnderHairType = data.searchUnderHairType;
    state.newCastFlag = data.newCastFlag;
    state.searchDrinkType = data.searchDrinkType;
    state.prefecture_id = data.prefecture_id;
    state.home_area_id = data.home_area_id;
    state.home_sub_area_id = data.home_sub_area_id;
    state.search_cast_age_min = data.search_cast_age_min;
    state.search_cast_age_max = data.search_cast_age_max;
    state.search_cast_height_min = data.search_cast_height_min;
    state.search_cast_height_max = data.search_cast_height_max;
    state.search_cast_cup_min = data.search_cast_cup_min;
    state.search_cast_cup_max = data.search_cast_cup_max;
    state.search_cast_s_degree = data.search_cast_s_degree;
    state.search_cast_m_degree = data.search_cast_m_degree;
    state.selectLanguage = data.selectLanguage;
    state.home_city_text = data.home_city_text;
    state.home_address = data.home_address;
    state.home_building = data.home_building;
    state.home_room_number = data.home_room_number;
    state.home_hope_flag = data.home_hope_flag;
    state.reservationNgFlag = data.reservationNgFlag;
    state.last_use_place_search_type = data.last_use_place_search_type;
    state.talent_ng_site_members = data.talent_ng_site_members;
    state.created_at = data.created_at;
    state.frequentlyPlayedArea = data.frequentlyPlayedArea;
    state.frequentlyPlayedGenreIds = data.frequentlyPlayedGenreIds;
    state.frequentlyPlayedShopId = data.frequentlyPlayedShopId;
    state.starPublicFlag = data.starPublicFlag;
    state.gradePublicFlag = data.gradePublicFlag;
    state.gradeId = data.gradeId;
    state.star = data.star;
    state.favoriteCastCount = data.favoriteCastCount;
    state.reviewCount = data.reviewCount;
    state.followCount = data.followCount;
    state.followerCount = data.followerCount;
    state.followCastIds = data.followCastIds;
  },
  // データを削除
  deleteSiteMemberData(state) {
    state.id = '';
    state.uuid = '';
    state.tel = '';
    state.nickname = '';
    state.selfIntroduction = '';
    state.imageFilename = '';
    state.profileImageUrl = '';
    state.noUseFlag = false;
    state.point = '';
    state.area_ids = '';
    state.castEvaluation = false;
    state.prefecture_id = '';
    state.search_cast_age_min = '';
    state.search_cast_age_max = '';
    state.search_cast_height_min = '';
    state.search_cast_height_max = '';
    state.search_cast_cup_min = '';
    state.search_cast_cup_max = '';
    state.search_cast_s_degree = '';
    state.search_cast_m_degree = '';
// state.search_cast_budget_num_min = '';
// state.search_cast_budget_num_max = '';
// state.earch_cast_budget_amount_min = '';
// state.search_cast_budget_amount_max = '';
    state.home_area_id = '';
    state.home_sub_area_id = '';
    state.home_city_text = '';
    state.home_address = '';
    state.home_building = '';
    state.home_hope_flag = 0;
    state.created_at = '';
    state.pointBalance = 0;
    state.gradeId = 0;
    state.star = 0;
    state.followCastIds = [];
  },
  // 情報を更新
  profileUpdate(state, data) {
    state.nickname = data.nickname;
    state.selfIntroduction = data.selfIntroduction;
    state.frequentlyPlayedArea = data.frequentlyPlayedArea;
    state.frequentlyPlayedGenreIds = data.frequentlyPlayedGenreIds;
    state.frequentlyPlayedShopId = data.frequentlyPlayedShopId;
    state.starPublicFlag = data.starPublicFlag;
    state.gradePublicFlag = data.gradePublicFlag;
  },
  recommend_site_member_important_cast_features(state, data) {
    state.recommend_site_member_important_cast_features = data;
  },
  homeUpdate(state, data) {
    state.prefecture_id = data.prefecture_id;
    state.home_area_id = data.home_area_id;
    state.home_sub_area_id = data.home_sub_area_id;
    state.home_city_text = data.home_city_text;
    state.home_address = data.home_address;
    state.home_building = data.home_building;
    state.home_room_number = data.home_room_number;
    state.selectPlace = data.home_hope_flag ? 'selected-home' : 'selected-hotel'
    state.home_hope_flag = data.home_hope_flag;
  },
  castAttributeUpdate(state, data) {
    state.search_cast_age_min = data.search_cast_age_min;
    state.search_cast_age_max = data.search_cast_age_max;
    state.search_cast_height_min = data.search_cast_height_min;
    state.search_cast_height_max = data.search_cast_height_max;
    state.search_cast_cup_min = data.search_cast_cup_min;
    state.search_cast_cup_max = data.search_cast_cup_max;
    state.search_cast_s_degree = data.search_cast_s_degree;
    state.search_cast_m_degree = data.search_cast_m_degree;
    state.castAgeValue = data.castAgeValue;
    state.castHeightValue = data.castHeightValue;
    state.castCupValue = data.castCupValue;
    state.castSmValue = data.castSmValue;
  },
  genreUpdate(state, data) {
    state.search_cast_genre_ids = data.search_cast_genre_ids;
  },
  searchShopUpdate(state, data) {
    state.search_cast_shop_ids = data.search_cast_shop_ids;
    state.search_cast_shop_obj = data.search_cast_shop_obj;
  },
  selectedCastIds (state, value) {
    state.selectedCastIds = value;
  },
  selectedCastObj(state, value) {
    state.selectedCastObj = value;
  },
  selectedCastUpdate(state, data) {
    state.selectedCastIds = data.selectedCastIds;
    state.selectedCastObj = data.selectedCastObj;
  },
  selectedHotelUpdate(state, data) {
    state.selectedHotelId = data.selectedHotelId;
    state.selectedHotelName = data.selectedHotelName;
    state.selectedHotel = data.selectedHotel;
  },
  castBudgetUpdate(state, data) {
    state.search_cast_budget_amount_min = data.search_cast_budget_amount_min;
    state.search_cast_budget_amount_max = data.search_cast_budget_amount_max;
    state.budgetValue = data.budgetValue;
  },
  castAgeUpdate(state, data) {
    state.search_cast_age_min = data.search_cast_age_min;
    state.search_cast_age_max = data.search_cast_age_max;
    state.castAgeValue = data.castAgeValue;
  },
  castHeightUpdate(state, data) {
    state.search_cast_height_min = data.search_cast_height_min;
    state.search_cast_height_max = data.search_cast_height_max;
    state.castHeightValue = data.castHeightValue;
  },
  castCupUpdate(state, data) {
    state.search_cast_cup_min = data.search_cast_cup_min;
    state.search_cast_cup_max = data.search_cast_cup_max;
    state.castCupValue = data.castCupValue;
  },
  castSmUpdate(state, data) {
    state.search_cast_s_degree = data.search_cast_s_degree;
    state.search_cast_m_degree = data.search_cast_m_degree;
    state.castSmValue = data.castSmValue;
  },
  areaUpdate(state, data) {
    state.mapAreaId = data.mapAreaId;
    state.area_ids = data.areaIds;
    state.selectPlace = data.selectPlace;
    state.search_prefecture_id = data.searchPrefectureId;
    state.selectedHotelId = data.selectedHotelId;
    state.selectedHotel = data.selectedHotel;
    state.otherAreaPanel = data.otherAreaPanel;
  },
  lastUsePlaceSearchTypeUpdate(state, data) {
    state.last_use_place_search_type = data;
  },
  areaIds(state, data) {
    state.area_ids = data;
  },
  subAreaIds(state, data) {
    state.sub_area_ids = data;
  },
  favaliteTagIds(state, data) {
    state.favaliteTagIds = data;
  },
  ngSmokeValue(state, data) {
    state.ngSmokeValue = data;
  },
  ngTattooValue(state, data) {
    state.ngTattooValue = data;
  },
  ngBodyPiercingFlag(state, data) {
    state.ngBodyPiercingFlag = data;
  },
  searchUnderHairType(state, data) {
    state.searchUnderHairType = data;
  },
  newCastFlag(state, data) {
    state.newCastFlag = data;
  },
  searchDrinkType(state, data) {
    state.searchDrinkType = data;
  },
  // 新顧客フラグを変更
  noUseFlag(state, flag) {
    state.noUseFlag = flag;
  },
  selectPlace(state, flag) {
    state.selectPlace = flag;
  },
  sortType (state, value) {
    state.sortType = value;
  },
  showAllCasts (state, value) {
    state.showAllCasts = value;
  },
  setProfilePlayOptionCategoryIds (state, value) {
    state.profilePlayOptionCategoryIds = value;
  },
  saveLikedPhotoDiaryLimit(state, data) {
    if (data.likedPhotoDiaryLimit == true || data.likedPhotoDiaryLimit == false) {
      state.likedPhotoDiaryLimit = data.likedPhotoDiaryLimit;
    }
    if (data.maxLikedPhotoDiary) {
      state.maxLikedPhotoDiary = data.maxLikedPhotoDiary;
    }
  },
  saveHelpFullReviewLimit(state, data) {
    if (data.helpFullReviewLimit == true || data.helpFullReviewLimit == false) {
      state.helpFullReviewLimit = data.helpFullReviewLimit;
    }
    if (data.maxHelpFullReview) {
      state.maxHelpFullReview = data.maxHelpFullReview;
    }
  },
  saveFollowSiteMemberLimit(state, data) {
    if (data.followSiteMemberLimit == true || data.followSiteMemberLimit == false) {
      state.followSiteMemberLimit = data.followSiteMemberLimit;
    }
    if (data.maxFollowSiteMember) {
      state.maxFollowSiteMember = data.maxFollowSiteMember;
    }
  },
  searchType (state, value) {
    state.searchType = value;
  },
  aboutSiteMemberInfoUpdate(state, data) {
    state.pointBalance = ((data.siteMemberGivingPointHistories) - (data.siteMemberLosePointHistories));
    state.gradeId = data.site_member_grade_id;
    state.star = data.star;
  },
  setFrequentlyPlayedGenreIds(state, data) {
    state.frequentlyPlayedGenreIds = data;
  },
  updateFollowCastIds(state, data) {
    state.followCastIds = data;
  },
  budgetValue (state, value) {
    state.budgetValue = value;
  },
  castAgeValue (state, value) {
    state.castAgeValue = value
  },
  castHeightValue (state, value) {
    state.castHeightValue = value
  },
  castCupValue (state, value) {
    state.castCupValue = value
  },
  castSmValue (state, value) {
    state.castSmValue = value
  },
}

export const actions = {
  saveSiteMemberData(context, data) {
    context.commit('saveSiteMemberData', data)
  },
  deleteSiteMemberData(context) {
    context.commit('deleteSiteMemberData')
  },
  profileUpdate(context, data) {
    context.commit('profileUpdate', data)
  },
  recommend_site_member_important_cast_features(context, data) {
    context.commit('recommend_site_member_important_cast_features', data)
  },
  homeUpdate(context, data) {
    context.commit('homeUpdate', data)
  },
  castAttributeUpdate(context, data) {
    context.commit('castAttributeUpdate', data)
  },
  genreUpdate(context, data) {
    context.commit('genreUpdate', data)
  },
  searchShopUpdate(context, data) {
    context.commit('searchShopUpdate', data)
  },
  selectedCastUpdate(context, data) {
    context.commit('selectedCastUpdate', data)
  },
  selectedCastIds(context, value) {
    context.commit('selectedCastIds', value);
  },
  selectedCastObj(context, value) {
    context.commit('selectedCastObj', value);
  },
  selectedHotelUpdate(context, data) {
    context.commit('selectedHotelUpdate', data)
  },
  castBudgetUpdate(context, data) {
    context.commit('castBudgetUpdate', data)
  },
  castAgeUpdate(context, data) {
    context.commit('castAgeUpdate', data)
  },
  castHeightUpdate(context, data) {
    context.commit('castHeightUpdate', data)
  },
  castCupUpdate(context, data) {
    context.commit('castCupUpdate', data)
  },
  castSmUpdate(context, data) {
    context.commit('castSmUpdate', data)
  },
  areaUpdate(context, data) {
    context.commit('areaUpdate', data)
  },
  lastUsePlaceSearchTypeUpdate(context, data) {
    context.commit('lastUsePlaceSearchTypeUpdate', data)
  },
  areaIds(context, data) {
    context.commit('areaIds', data)
  },
  subAreaIds(context, data) {
    context.commit('subAreaIds', data)
  },
  favaliteTagIds(context, data) {
    context.commit('favaliteTagIds', data)
  },
  ngSmokeValue(context, data) {
    context.commit('ngSmokeValue', data)
  },
  ngTattooValue(context, data) {
    context.commit('ngTattooValue', data)
  },
  ngBodyPiercingFlag(context, data) {
    context.commit('ngBodyPiercingFlag', data)
  },
  searchUnderHairType(context, data) {
    context.commit('searchUnderHairType', data)
  },
  newCastFlag(context, data) {
    context.commit('newCastFlag', data)
  },
  searchDrinkType(context, data) {
    context.commit('searchDrinkType', data)
  },
  noUseFlag(context, flag) {
    context.commit('noUseFlag', flag)
  },
  selectPlace(context, flag) {
    context.commit('selectPlace', flag)
  },
  async sortType({ commit }, value) {
    commit('sortType', value);
  },
  async showAllCasts({ commit }, value) {
    commit('showAllCasts', value);
  },
  async commitProfilePlayOptionCategoryIds(context, payload) {
    context.commit('setProfilePlayOptionCategoryIds', payload);
  },
  saveLikedPhotoDiaryLimit(context, data) {
    context.commit('saveLikedPhotoDiaryLimit', data)
  },
  saveHelpFullReviewLimit(context, data) {
    context.commit('saveHelpFullReviewLimit', data)
  },
  saveFollowSiteMemberLimit(context, data) {
    context.commit('saveFollowSiteMemberLimit', data)
  },
  async searchType({ commit }, value) {
    commit('searchType', value);
  },
  aboutSiteMemberInfoUpdate(context, data) {
    context.commit('aboutSiteMemberInfoUpdate', data)
  },
  commitFrequentlyPlayedGenreIds(context, data) {
    context.commit('setFrequentlyPlayedGenreIds', data);
  },
  commitFollowCastIds(context, data) {
    context.commit('updateFollowCastIds', data);
  },
  async budgetValue ({ commit }, value) {
    commit('budgetValue', value);
  },
  async castAgeValue({ commit }, value) {
    commit('castAgeValue', value);
  },
  async castHeightValue({ commit }, value) {
    commit('castHeightValue', value);
  },
  async castCupValue({ commit }, value) {
    commit('castCupValue', value);
  },
  async castSmValue({ commit }, value) {
    commit('castSmValue', value);
  },
}
